import './App.css';
import app from 'firebase/app';
import 'firebase/analytics';
 app.initializeApp({
    apiKey: "AIzaSyD8XHGMom5Mjw6vligaGIsGk683dsJqWdM",
    authDomain: "blingbling-b1c85.firebaseapp.com",
    projectId: "blingbling-b1c85",
    storageBucket: "blingbling-b1c85.appspot.com",
    messagingSenderId: "673825014460",
    measurementId : "G-V0B3VL46SG",
    appId : "1:673825014460:web:dddc9128a2e8c9c4f386b9"
})
app.analytics()
/* NONE FIREBASE GOOGLE ANALYTICS
import ReactGA from 'react-ga';
const trackingId = "UA-44970290-1"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search);
*/
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="App-banner" alt="banner">
          <div className="App-bannerAligner"></div>
          <div className="App-bannerText">
            <h1>BLING BLING ON QUEEN</h1>
            <p>Pearl, Crystal, Gem Stone, Beads, Silver & Things</p>
          </div>
        </div>
      </header>
      <body className="App-body">
        <div className="App-menu">
          <span><b>
            OUR STORY
          </b></span>
        </div>
        <div className="App-content">
          <p>
            Located at the heart of Toronto’s fashion district on Queen West. Bling Bling has been proudly serving the beading community since 2005.
          </p>
          <p>
            With a generous selection of gem stones, pearl, beads, findings, sterling silver, chains and many other beading supplies, our goal is to satisfy your beading needs and make your creative designs possible. We source our stones and beads from around the world, providing our customers a large selection to choose from. We also have a dedicated wall for fresh water pearls and mother of pearls in various shapes and forms. Bling Bling also carry a huge selection of finding, wires, elastics, threads, pliers and other beading supply.
          </p>
          <p>
            Drop by our showroom at Queen and Spadina. You will not be disappointed.
          </p>
          <p><b>
            Follow our instagram and facebook for our latest promotions and update!
          </b></p>
          <div className="App-socialwrapper">
              <a href="https://www.instagram.com/blingblingonqueen/">
                <img src={"instagram-128x128.png"} className="App-social" alt="igLogo"/>
              </a>
              <a href="https://www.facebook.com/blingblingonqueen/">
                <img src={"facebook-128x128.png"} className="App-social" alt="fbLogo"/>
              </a>
          </div>
        </div>
      </body>
      <body className="App-body">
        <div className="App-menu">
          <span><b>
            LOCATION
          </b></span>
        </div>
        <div className="App-content">
          <br/>
          <span>
            <b>Reopened for in store shopping!</b>
          </span>
          <br/>
          <span>
            <b>Address</b>
          </span>
          <span>
            448 Queen Street West
          </span>
          <span>
            Toronto, Ontario, M5V2A8
          </span>
          <br/>
          <span>
            <b>Hours</b>
          </span>
          <span>
            Monday to Saturday: 10:00am to 6:00pm
          </span>
          <span>
            Sunday and Holidays: 12:00pm to 5:00pm
          </span>
          <br/>
          <span>
            <b>Contact</b>
          </span>
          <span>
            info@blingblingonqueen.ca
          </span>
          <span>
            (416) 365 0880
          </span>
          <br/>
          <img src={"bblogo.png"} className="App-logo" alt="logo" />
        </div>
      </body>
      <footer>
        <div className="App-footer">
          <p>© Copyright {new Date().getFullYear()} - Bling Bling & Ken Inc</p>
        </div>
      </footer>
    </div>
  );
}

export default App;
